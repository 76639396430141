@import url('https://fonts.googleapis.com/css?family=Roboto');

html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.camera_input_wine {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.camera_label_wine {
  border-width: 1;
  border-radius: 2;
  background-color: rgb(33, 150, 243);
  cursor: pointer;
  color: rgb(255, 255, 255);
  padding: 8px;
  font-weight: 500;
  font: 14px;
  text-transform: uppercase;
  display: inline-block;
}
